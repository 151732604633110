import React, { Component } from "react";
import {Redirect} from 'react-router-dom';

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [Session Invalidation]
 */
class Logout extends Component {
  constructor(props) {
    super(props);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("creditors");
    localStorage.removeItem("userName");
    localStorage.removeItem("creditorId");
  }
  state = {};
  render() {
    return <Redirect to="/" />;
  }
}

export default Logout;
