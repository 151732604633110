import React, { Component, Fragment } from "react";
import { SearchService } from "../services/SearchService";
import "../home.css";
import Pagination from "react-js-pagination";
import ToggleDisplay from "react-toggle-display";
import { LoginService } from "../services/LoginService";
import Alerts from "./Alerts";
import FFNLogo from "./FFN-LOGO.jpg";

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [service to call search rest API]
 */

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      loading: false,
      searchResult: [],
      showTable: false,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      pageRangeDisplayed: 3,
      userList: [],
      selectedUser: "",
      showDropDown: false,
      lastRow: 0,
      error: "",
      alertsError: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  componentWillMount() {
    this.getUsers();
  }

  getUsers() {
    let users = [];

    this.setState({ loading: true });
    LoginService.getAllUsers().then(
      data => {
        users = data.map(userResponse => {
          return userResponse;
        });
        console.log(users);
        this.setState({
          userList: users
        });
        this.setState({ loading: false });
        this.setState({ showDropDown: true });
        this.setState({ selectedUser: users[0].username });
      },
      error => {
        this.setState({ loading: false });
        this.setState({ error, loading: false });
        this.setState({
          error: error.message,
          alertsError: true
        });
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const size = `${process.env.REACT_APP_API_SIZE}`;
    this.setState({ pageSize: size });

    const startIndex = `${process.env.REACT_APP_API_START_INDEX}`;
    let userName = this.state.selectedUser;

    SearchService.search(size, startIndex, userName, this.state.status).then(
      result => {
        this.setState({ loading: false });
        this.setState({
          searchResult: result.sifUploadDetails,
          totalCount: result.totalCount,
          pageSize: result.pageSize,
          currentPage: result.pageNumber
        });

        this.setState({
          showTable: true
        });
        console.log("########## ", this.state.searchResult);
      },
      error => {
        this.setState({ loading: false });
        this.setState({ error, loading: false });
        this.setState({
          error: error.message,
          alertsError: true
        });
      }
    );
  }

  renderTableData() {
    // Pagination
    const { searchResult } = this.state;
    return searchResult.map((response, index) => {
      const {
        id,
        originalFileName,
        sfCaseId,
        sftpFileLocation,
        createdOn
      } = response; //destructuring
      return (
        <tr key={id}>
          <td>{originalFileName}</td>
          <td>{sfCaseId}</td>
          <td>{sftpFileLocation}</td>
          <td>{createdOn}</td>
        </tr>
      );
    });
  }

  renderTableHeader() {
    let header = Object.keys({
      originalFileName: "",
      sfCaseId: "",
      sftpFileLocation: "",
      createdOn: ""
    });
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleUserChange = event => {
    console.log("User on change : ", event.target.value);
    this.setState({
      selectedUser: event.target.value
    });
  };

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ currentPage: pageNumber });

    let size = this.state.pageSize;
    let offset = 0;
    let last = this.state.lastRow;
    let diff = this.state.totalCount - last;
    if (diff <= size) {
      last = 0;
    }
    if (pageNumber === 0) {

    }
    else {
      offset = last + size;
    }

    SearchService.search(size, offset, this.state.selectedUser, this.state.status).then(
      result => {
        this.setState({
          searchResult: result.sifUploadDetails,
          totalCount: result.totalCount,
          pageSize: result.pageSize,
          lastRow: offset
        });
      }
    );
  }

  render() {
    return (
      <Fragment>
        <div>
          <div className="form-group row" style={{
            backgroundColor: "#708CB1",

          }}>
            <div className="col-sm-4" style={{
              paddingTop: 35,
              paddingBottom: 15
            }}>
              <img src={FFNLogo} alt="ffn_logo" width="100" height="50" />
            </div>
            <div className="col-sm-3">
              <div
                style={{
                  backgroundColor: "#708CB1",
                  color: "black",
                  fontSize: 18,
                  textAlign: "center",
                  marginBottom: 20,
                  paddingTop: 30,
                  paddingBottom: 10
                }}
              >
                <i>
                  <h1>Search Uploads</h1>
                </i>

              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: 20, border: "2px solid eee" }}>
          <form name="form" onSubmit={this.handleSubmit}>
            <div className="form-group row" style={{ margin: 40 }}>
              <label htmlFor="status" className="col-sm-2 col-form-label">
                Status
                </label>
              <div className="col-sm-2">
                <input
                  type="text"
                  className="form-control"
                  name="status"
                  value={this.state.status}
                  placeholder="Status"
                  onChange={this.handleChange}
                />
              </div>
              <label htmlFor="userName" className="col-sm-2 col-form-label">
                UserName
                </label>
              <div className="col-sm-2">
                {this.state.loading && (
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loading" />
                )}
                <ToggleDisplay show={this.state.showDropDown}>
                  <select
                    value={this.state.selectedUser}
                    onChange={this.handleUserChange}
                    name="selectedUser"
                    className="form-control"
                  >
                    {this.state.userList.map(team => (
                      <option key={team.username} value={team.username}>
                        {team.username}
                      </option>
                    ))}
                  </select>
                </ToggleDisplay>
              </div>
              <div className="col-sm-3">
                <button
                  className="btn btn-primary"
                  disabled={!this.state.status}
                >
                  Submit
                  </button>
              </div>
            </div>
            <div className="form-group row">
              {this.state.alertsError === true ? <Alerts alertMessage={this.state.error} /> : null}
            </div>
            <div className="form-group row">
              <ToggleDisplay show={this.state.showTable}>
                <div>
                  <h3 id="title" className="title">
                    Upload Response
                    </h3>
                  <table
                    id="uploadResponseList"
                    className="uploadResponseList"
                  >
                    <tbody>
                      <tr>{this.renderTableHeader()}</tr>
                      {this.renderTableData()}
                    </tbody>
                  </table>
                </div>
              </ToggleDisplay>
            </div>
            <div className="form-group row">
              <ToggleDisplay show={this.state.showTable}>
                <Pagination
                  activePage={this.state.currentPage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={this.state.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </ToggleDisplay>
            </div>
          </form>
        </div>

      </Fragment>
    );
  }
}

export default Search;
