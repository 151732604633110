import axios from "axios";

export const UploadFileToGCSService = {
    UploadFileToGCSAndCreateDirectory
};

function UploadFileToGCSAndCreateDirectory(creditorId, creditorName) {
    let apiUrl = `${process.env.REACT_APP_API_FILE_UPLOAD_TO_GCS}` + creditorName + "/creditorId/" + creditorId ;
    console.log("Service URL --> "+apiUrl);

    return axios
        .post(apiUrl)
        .then(response => {
            console.log("response --> "+JSON.stringify(response.status));
            return response.status;
        }).catch(e => console.log("exception in service", e));
}