import axios from "axios";

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [service to call uploadMultipleFiles rest API]
 */
export const UploadFileService = {
  uploadFile
};

const headers = {
  'sourceid': `${process.env.REACT_APP_API_HEADER_SECURITY_SOURCE_ID}`,
  'token':` ${process.env.REACT_APP_API_HEADER_SECURITY_TOKEN}`
};

function uploadFile(uploadRequest, userName, creditorId, uploadDate) {
  let apiUrl =
    `${process.env.REACT_APP_API_SIF_MULTI_UPLOAD_URL}/` +
    userName +
    "/creditor/" +
    creditorId +
    "/date/" +
    uploadDate;

  return axios
    .post(apiUrl, uploadRequest, { headers: headers })
    .then(response => {
      if (response.status === 200) {
      } else {
        alert("Case/Attachment creation Failed");
      }
      return response.data;
    });
}
