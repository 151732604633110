import React, { Component, Fragment } from "react";
import { CreditorService } from "../services/CreditorService";
import "../home.css";
import ToggleDisplay from "react-toggle-display";

import { Link, Redirect } from "react-router-dom";

import Alerts from "./Alerts";

import { UploadFileToGCSService } from "../services/UploadFileToGCSService";

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [Get creditor names for a given name and upload multiple requested file]
 */


class CreateCreditorInStorage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creditorName: "",

            loading: false,

            creditors: [],
            selectedCreditor: "",
            showTable: false,
            showDropDown: false,
            showResult: false,
            result: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCreditor = this.handleChangeCreditor.bind(this);
        this.handleCredChange = this.handleCredChange.bind(this);
        this.handleCreateDir = this.handleCreateDir.bind(this);
    }

    render() {

        if (this.state.loggedIn === false) {
            return <Redirect to="/" />;
        }

        const { creditorName, loading } = this.state;

        return (
            <Fragment>
                <div><br></br></div>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="creditorName" className="col-sm-3 col-form-label">
                            Creditor Name
                        </label>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="form-control"
                                name="creditorName"
                                value={creditorName}
                                placeholder="Creditor Name"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-sm-3">
                            <button
                                className="btn btn-primary"
                                disabled={!this.state.creditorName}
                            >
                                Get Creditors
                            </button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label
                            htmlFor="selectedCreditor"
                            className="col-sm-3 col-form-label"
                        >
                            <ToggleDisplay show={this.state.showDropDown}>
                                &nbsp;&nbsp; Select Creditor
                        </ToggleDisplay>
                        </label>
                        <div className="col-sm-3">
                            <ToggleDisplay show={this.state.showDropDown}>
                                <select
                                    value={this.state.selectedCreditor}
                                    onChange={this.handleCredChange}
                                    name="selectedCreditor"
                                    className="form-control"
                                >
                                    {this.state.creditors.map(team => (
                                        <option key={team.Id} value={team.Id}>
                                            {team.Name}
                                        </option>
                                    ))}
                                </select>
                            </ToggleDisplay>
                        </div>
                        <div className="col-sm-3">
                            <ToggleDisplay show={this.state.showDropDown}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={!this.state.showDropDown}
                                    onClick={this.handleCreateDir}
                                >
                                    Create Directory
                                </button>
                            </ToggleDisplay>
                        </div>
                    </div>

                    <div className="form-group row">

                        <div className="col-sm-2">
                            {loading && (
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loading" />
                            )}
                        </div>
                    </div>

                    <div className="form-group row" />
                    <div className="form-group row" />

                    <div className="form-group row">
                        <div style={{
                            marginTop: 1,
                            marginLeft: 120,
                            marginRight: 120
                        }}>
                            <ToggleDisplay show={this.state.showResult}>
                                <h3 id="title" className="title">
                                    {this.state.result}
                                </h3>
                            </ToggleDisplay>
                        </div>
                    </div>

                    <div className="form-group row" />
                    <div className="form-group row" />

                    <div className="form-group row">
                        <div className="col-sm-3" />
                        <div className="col-sm-4">
                            {this.state.alertsError === true ? <Alerts alertMessage={this.state.error} /> : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-2">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/logout">Log out </Link>
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }

    handleChangeCreditor(e) {
        this.setState({ selectedCreditor: e.target.value });
        console.log("---selected creditor---- ", this.state.selectedCreditor);
    }

    handleCredChange = event => {
        console.log("Creditor on change : ", event.target.value);
        this.setState({
            selectedCreditor: event.target.value
        });
        localStorage.setItem("creditorId", this.state.selectedCreditor);
    };

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        const { creditorName } = this.state;
        e.preventDefault();
        this.setState({ submitted: true });
        // stop here if form is invalid
        if (!creditorName) {
            return;
        }
        let initialCreditors = [];

        this.setState({ loading: true });
        CreditorService.getByName(creditorName).then(
            data => {
                initialCreditors = data.map(planet => {
                    return planet;
                });
                console.log(initialCreditors);
                this.setState({
                    creditors: initialCreditors
                });
                this.setState({ loading: false });

                if (initialCreditors.length === 0) {
                    this.setState({ showDropDown: false });
                } else {
                    this.setState({ showDropDown: true });
                }
            },
            error => {
                this.setState({ loading: false });
                this.setState({ error, loading: false });
                this.setState({
                    error: error.message,
                    alertsError: true
                });
            }
        );
    }

    handleCreateDir = event => {
        event.preventDefault();

        let creditorList = this.state.creditors;

        let creditorId = this.state.selectedCreditor;
        if (this.state.selectedCreditor === "") {
            creditorId = creditorList[0].Id;
        }

        var cred = creditorList.filter(creditor => creditor.Id === creditorId)[0];

        let selectedCreditorName = this.state.creditorName;

        if (cred != null) {
            selectedCreditorName = cred.Name;
        } else {
            selectedCreditorName = creditorList[0].Name;
        }

        if (selectedCreditorName.indexOf('/') > 0 || selectedCreditorName.indexOf('\\')) {
            selectedCreditorName = selectedCreditorName.replace('/', '');
        }

        UploadFileToGCSService.UploadFileToGCSAndCreateDirectory(creditorId, selectedCreditorName).then(
            data => {
                if (data === 208) {
                    console.log("Directory Already Exists!!!");
                    this.setState({ result: "Directory Already Exists!!!" });
                } else if (data === 200) {
                    console.log("Directory created successfully!!!");
                    this.setState({ result: "Directory created successfully!!!" });
                } else if (data === 400) {
                    console.log("Invalid Creditor Id!!!");
                    this.setState({ result: "Invalid Creditor Id!!!" });
                }

                this.setState({ showResult: true });
            },
            error => {
                console.log("Error........... : " + error.stack);
                this.setState({ loading: false });
                this.setState({ error, loading: false });
                this.setState({
                    error: error.message,
                    alertsError: true
                });
            }
        );
    };

}

export default CreateCreditorInStorage;
