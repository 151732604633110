import React, { Component, Fragment } from "react";
import { CreditorService } from "../services/CreditorService";
import { UploadFileService } from "../services/UploadFileService";
import DatePicker from "react-datepicker";
import "../home.css";
import ToggleDisplay from "react-toggle-display";

import "react-datepicker/dist/react-datepicker.css";

import { Link, Redirect } from "react-router-dom";

import Alerts from "./Alerts";

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [Get creditor names for a given name and upload multiple requested file]
 */
let userName = "";

class FileUpload extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token");
        userName = localStorage.getItem("userName");
        let loggedIn = true;
        if (token == null) {
            loggedIn = false;
        }

        this.state = {
            creditorName: "",
            submitted: false,
            loading: false,
            error: "",
            creditors: [],
            loggedIn,
            selectedFile: null,
            selectedCreditor: "",
            startDate: new Date(),
            uploadResponseList: [],
            showTable: false,
            uploadCount: 0,
            showDropDown: false,
            showCount: false,
            message: "",
            alertsError: false,
            isAdmin: false,
            uploadSuccessCount: 0,
            uploadFailedCount: 0,
            failedFileNames: "",
            isFailedUpload: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.handleChangeCreditor = this.handleChangeCreditor.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleCredChange = this.handleCredChange.bind(this);
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        });
    }

    componentWillMount() {
        if (userName === "admin" || userName === "Admin") {
            this.setState({ isAdmin: true });
        }
    }

    render() {
        if (this.state.loggedIn === false) {
            return <Redirect to="/" />;
        }

        const { creditorName, loading } = this.state;

        return (
            <Fragment>
                <div>

                    <ToggleDisplay show={this.state.isAdmin}>
                        <div className="d-flex flex-row-reverse">
                            <div className="p-2" />
                            <div className="p-2" />
                            <div className="p-2">
                                <Link to="/search">Search Uploads </Link>
                            </div>
                        </div>
                    </ToggleDisplay>
                </div>
                <div><br></br></div>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="creditorName" className="col-sm-3 col-form-label">
                            Creditor Name
                        </label>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="form-control"
                                name="creditorName"
                                value={creditorName}
                                placeholder="Creditor Name"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-sm-3">
                            <button
                                className="btn btn-primary"
                                disabled={!this.state.creditorName}
                            >
                                Get Creditors
              </button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label
                            htmlFor="selectedCreditor"
                            className="col-sm-3 col-form-label"
                        >
                            <ToggleDisplay show={this.state.showDropDown}>
                                &nbsp;&nbsp; Selected Creditor
              </ToggleDisplay>
                        </label>
                        <div className="col-sm-3">
                            <ToggleDisplay show={this.state.showDropDown}>
                                <select
                                    value={this.state.selectedCreditor}
                                    onChange={this.handleCredChange}
                                    name="selectedCreditor"
                                    className="form-control"
                                >
                                    {this.state.creditors.map(team => (
                                        <option key={team.Id} value={team.Id}>
                                            {team.Name}
                                        </option>
                                    ))}
                                </select>
                            </ToggleDisplay>
                        </div>
                        <div className="col-sm-3" />
                    </div>

                    <div className="form-group row">
                        <label htmlFor="startDate" className="col-sm-3 col-form-label">
                            <ToggleDisplay show={this.state.showDropDown}>
                                &nbsp; &nbsp;&nbsp; First Payment Date
              </ToggleDisplay>
                        </label>
                        <div className="col-sm-2">
                            <ToggleDisplay show={this.state.showDropDown}>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleDateChange}
                                    name="startDate"
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                />
                            </ToggleDisplay>
                        </div>
                        <div className="col-sm-7" />
                    </div>

                    <div className="form-group row">
                        <div className="col-sm-4">
                            <ToggleDisplay show={this.state.showDropDown}>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                                    type="file"
                                    name="file"
                                    multiple
                                    onChange={this.onChangeHandler}
                                />
                            </ToggleDisplay>
                        </div>
                        <div className="col-sm-2">
                            {loading && (
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loading" />
                            )}
                        </div>
                        <div className="col-sm-3">
                            <ToggleDisplay show={this.state.showDropDown}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={!this.state.selectedFile}
                                    onClick={this.onUpload}
                                >
                                    Upload Files
                </button>
                            </ToggleDisplay>
                        </div>
                    </div>

                    <div className="form-group row" />
                    <div className="form-group row" />

                    <div className="form-group row">
                        <div style={{
                            marginTop: 1,
                            marginLeft: 100,
                            marginRight: 100
                        }}>
                            <ToggleDisplay show={this.state.showCount}>
                                <h3 id="title" className="title">
                                    Upload Results
                </h3>
                                <table
                                    id="uploadResponseList"
                                    className="uploadResponseList"
                                >
                                    <tbody>
                                        <tr>
                                            <th>Successful-uploads </th>
                                            <td>{this.state.uploadSuccessCount}</td>
                                        </tr>
                                        <tr>
                                            <th>Failed-uploads</th>
                                            <td>{this.state.uploadFailedCount}</td>
                                        </tr>
                                        <tr>
                                            <th>Failed-files</th>
                                            <td>{this.state.failedFileNames}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ToggleDisplay>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-3" />
                        <div className="col-sm-4">
                            {this.state.alertsError === true ? <Alerts alertMessage={this.state.error} /> : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-2">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/logout">Log out </Link>
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }

    onUpload(e) {
        e.preventDefault();
        console.log("Creditor : ", this.state.selectedCreditor);

        let day = this.state.startDate.getUTCDate();
        if (day < 10) {
            day = "0" + day;
        }
        let month = this.state.startDate.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        let uploadDate =
            this.state.startDate.getFullYear() + "-" + month + "-" + day;

        console.log("Selected Date ------ " + uploadDate);

        let creditorId = this.state.selectedCreditor;
        if (this.state.selectedCreditor === "") {
            creditorId = this.state.creditors[0].Id;
        }

        this.setState({ loading: true });

        const data = new FormData();
        for (var x = 0; x < this.state.selectedFile.length; x++) {
            data.append("files", this.state.selectedFile[x]);
        }

        UploadFileService.uploadFile(data, userName, creditorId, uploadDate).then(
            result => {
                this.setState({ loading: false });
                this.setState({
                    uploadResponseList: result
                });
                this.setState({
                    showTable: true
                });
                console.log("########## ", this.state.uploadResponseList);
                const msg = this.renderOutput();
                console.log("Result message : ", msg);
                this.setState({
                    message: msg
                });
                this.setState({
                    showCount: true
                });
            },
            error => {
                console.log("Error........... : " + error.stack);
                this.setState({ loading: false });
                this.setState({ error, loading: false });
                this.setState({
                    error: error.message,
                    alertsError: true
                });
            }
        );
    }

    onChangeHandler(e) {
        if (this.maxSelectFile(e)) {
            this.setState({
                selectedFile: e.target.files
            });
        }
    }

    maxSelectFile = e => {
        let files = e.target.files; // create file object
        const uploadLimit = `${process.env.REACT_APP_API_MAX_FILES_TO_UPLOAD}`;
        if (files.length > uploadLimit) {
            e.target.value = null; // discard selected file
            const alertMsg =
                "Sorry... We do not support more than " +
                uploadLimit +
                " files in single upload";
            this.setState({
                alertsError: true,
                error: alertMsg
            });
            return false;
        }
        return true;
    };

    handleChangeCreditor(e) {
        this.setState({ selectedCreditor: e.target.value });
        console.log("---selected creditor---- ", this.state.selectedCreditor);
    }

    handleCredChange = event => {
        console.log("Creditor on change : ", event.target.value);
        this.setState({
            selectedCreditor: event.target.value
        });
        localStorage.setItem("creditorId", this.state.selectedCreditor);
    };

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        const { creditorName } = this.state;
        e.preventDefault();
        this.setState({ submitted: true });
        // stop here if form is invalid
        if (!creditorName) {
            return;
        }
        let initialCreditors = [];

        this.setState({ loading: true });
        CreditorService.getByName(creditorName).then(
            data => {
                initialCreditors = data.map(planet => {
                    return planet;
                });
                console.log(initialCreditors);
                this.setState({
                    creditors: initialCreditors
                });
                this.setState({ loading: false });
                this.setState({ showDropDown: true });
            },
            error => {
                this.setState({ loading: false });
                this.setState({ error, loading: false });
                this.setState({
                    error: error.message,
                    alertsError: true
                });
            }
        );
    }

    renderOutput() {
        let resultList = [];
        let successResultList = [];
        let value = "";
        resultList = this.state.uploadResponseList.failedFiles;
        successResultList = this.state.uploadResponseList.successfullFiles;
        this.setState({
            uploadSuccessCount: successResultList.length,
            uploadFailedCount: resultList.length
        });
        if (resultList.length > 0) {
            for (let i = 0; i < resultList.length; i++) {
                if (i === resultList.length - 1) {
                    value = value + resultList[i].fileName;
                } else {
                    value = value + resultList[i].fileName + ", ";
                }
            }
            this.setState({ isFailedUpload: true });
        } else {
            value = "-";
        }
        this.setState({ failedFileNames: value });
    }

}

export default FileUpload;