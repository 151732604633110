/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [servivce to call getCreditorByName rest API]
 */

export const CreditorService = {
    getByName
  };
  
  function getByName(creditorName) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    };
  
    let apiUrl = `${process.env.REACT_APP_API_CREDITOR_URL}`;

    return fetch(apiUrl+creditorName, requestOptions)
      .then(handleResponse)
      .then(creditor => {
       
        if (creditor) {
          localStorage.setItem("creditors", JSON.stringify(creditor));
        }
  
        return creditor;
      });
  }
  
  function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
  
      return data;
    });
  }
  