import React from "react";
import "./App.css";
import Login from "./components/Login";
import Home from "./components/Home";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Logout from "./components/Logout";
import Search from "./components/Search";
import DirInStorage from "./components/CreateCreditorInStorage";
import FileUpload from "./components/FileUpload";

function App() {
  return (
    <Router>
      <div className="App">
        <div>
          <Route path="/" exact={true} component={Login} />
          <Route path="/home" component={Home} />
          <Route path="/fileUpload" component={FileUpload} />
          <Route path="/logout" component={Logout} />
          <Route path="/search" component={Search} />
          <Route path="/createDirectoryInStorage" component={DirInStorage} />
        </div>
      </div>
    </Router>
  );
}

export default App;
