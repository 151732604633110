import axios from "axios";

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [Auth service to aunthenticate user for login]
 */
export const LoginService = {
  login,
  logout,
  getAllUsers
};

function login(reqBody) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody)
  };

  return fetch(`${process.env.REACT_APP_API_AUTH_URL}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
      }

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getAllUsers() {
  let apiUrl = `${process.env.REACT_APP_API_GET_USERS}`;

  return axios.get(apiUrl, {}).then(response => {
    if (response.status === 200) {
    } else {
      //alert("Search Failed");
    }
    return response.data;
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
