import axios from "axios";

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [service to call search rest API]
 */
export const SearchService = {
  search
};

function search(nsize,pageNumber, userName, status) {
  let apiUrl =
    `${process.env.REACT_APP_API_SEARCH_URL}/` + userName + "/status/" + status;

  return axios
    .get(apiUrl, {
      params: {
        pageSize: nsize,
        pageNumber: pageNumber
      }
    })
    .then(response => {
      if (response.status === 200) {
      } else {
        //alert("Search Failed");
      }
      return response.data;
    });
}
