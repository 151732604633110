import React, { Component, Fragment } from "react";
import "../home.css";
import FFNLogo from "./FFN-LOGO.jpg";
import Tabs from './Tabs';
import FileUpload from "./FileUpload";
import DirInStorage from "./CreateCreditorInStorage";
import "../tab.css";
import { Redirect } from "react-router-dom";

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [Get creditor names for a given name and upload multiple requested file]
 */
let userName = "";

class Home extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    userName = localStorage.getItem("userName");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn
    };
  }

  componentWillMount() {
    if (userName === "admin" || userName === "Admin") {
      this.setState({ isAdmin: true });
    }
  }

  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }

    let user = localStorage.getItem("userName");
    return (
      <Fragment>
        <div>
          <div className="form-group row" style={{
            backgroundColor: "#708CB1",

          }}>
            <div className="col-sm-4" style={{
              paddingTop: 35,
              paddingBottom: 15
            }}>
              <img src={FFNLogo} alt="ffn_logo" width="100" height="50" />
            </div>
            <div className="col-sm-3">
              <div
                style={{
                  backgroundColor: "#708CB1",
                  color: "black",
                  fontSize: 18,
                  textAlign: "center",
                  marginBottom: 20,
                  paddingTop: 30,
                  paddingBottom: 10
                }}
              >
                <i>
                  <h1>Welcome {user}</h1>
                </i>

              </div>
            </div>

          </div>
        </div>

        <div className="form-group row">
          <Tabs>
            <div label="File Upload">
              <FileUpload />
            </div>
            <div label="Create Directory">
              <DirInStorage />
            </div>
          </Tabs>
        </div>
      </Fragment>
    );
  }

}

export default Home;
