import React, { Component, Fragment } from "react";
import { LoginService } from "../services/LoginService";
import { Redirect } from "react-router-dom";
import Alerts from "./Alerts";
import FFNLogo from "./FFN-LOGO.jpg";
//import history from "./History";

const secretToken = `${process.env.REACT_APP_API_SECRET_TOKEN}`;

/**
 *
 * @author [Minal Wadkar](mwadkar@freedomfinancialnetwork.com)
 * @description [Login using username and password]
 */
class Login extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      username: "",
      password: "",
      submitted: false,
      loading: false,
      error: "",
      loggedIn,
      alertsError: false,
      loginError: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    if (this.state.loggedIn) {
      return <Redirect to="/home" />;
    }

    const { username, password, submitted, loading, error } = this.state;

    return (
      <Fragment>

        <div>
          <div className="form-group row" style={{
            backgroundColor: "#708CB1",

          }}>
            <div className="col-sm-4" style={{
              paddingTop: 35,
              paddingBottom: 15
            }}>
              <img src={FFNLogo} alt="ffn_logo" width="100" height="50" />
            </div>
            <div className="col-sm-3">
              <div
                style={{
                  backgroundColor: "#708CB1",
                  color: "black",
                  fontSize: 18,
                  textAlign: "center",
                  marginBottom: 20,
                  paddingTop: 30,
                  paddingBottom: 10
                }}
              >
                <i>
                  <h1>Login</h1>
                </i>

              </div>
            </div>
          </div>
        </div>

        <form name="form" onSubmit={this.handleSubmit}>
          <div
            className={
              "form-group" + (submitted && !username ? " has-error" : "")
            }
          >
            <div className="form-group row" style={{ marginTop: 100, marginBottom: 50 }}>
              <div className="col-sm-2" />
              <label htmlFor="username" className="col-sm-3 col-form-label">
                Username
            </label>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={this.handleChange}
                />
                {submitted && !username && (
                  <div className="help-block">Username is required</div>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              "form-group" + (submitted && !password ? " has-error" : "")
            }
          >
            <div className="form-group row" style={{ marginBottom: 50 }}>
              <div className="col-sm-2" />
              <label htmlFor="password" className="col-sm-3 col-form-label">
                Password
            </label>
              <div className="col-sm-3">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
                {submitted && !password && (
                  <div className="help-block">Password is required</div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-2" />
            <div className="col-sm-3">
              <button className="btn btn-primary" disabled={loading}>
                Login
                </button>
              {loading && (
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loading" />
              )}
            </div>
          </div>
          <div className="form-group row">
            {error && <div className={"alert alert-danger"}>{error}</div>}
            <div className="col-sm-4">
              {this.state.alertsError === true ? <Alerts alertMessage={this.state.loginError} /> : null}
            </div>
          </div>
        </form>
      </Fragment>
    );
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    const { username, password } = this.state;
    e.preventDefault();

    console.log("Authentication started for" + username);

    let reqBody = {
      username: username,
      password: password
    };

    this.setState({ submitted: true });

    // stop here if form is invalid
    if (!(username && password)) {
      return;
    }

    this.setState({ loading: true });

    LoginService.login(reqBody).then(
      user => {
        if (user.success) {
          console.log(user.message);
          localStorage.setItem("token", secretToken);
          localStorage.setItem("userName", user.userName);
          this.setState({ loggedIn: true });
          this.setState({ loading: false });
        }
      },
      error => {
        this.setState({
          alertsError: true,
          loginError: error.message
        });
        console.log(error.message);
        this.setState({ loading: false });
        setTimeout(
          function () {
            //Start the timer
            window.location.reload(); //After 5 second, reload the page
          },
          5000
        );
      }
    );
  }
}

export default Login;
